<template>
    <div class="info">
      <top-bar :title="'新增小事'" :left="true"></top-bar>

      <van-popup v-model="categoryshow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.categoryList"  value-key="label"  @confirm="categoryConfirm"  @cancel="categoryshow = false"/>
      </van-popup>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <van-popup v-model="positionShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.positionList" value-key="label"  @confirm="positionConfirm"  @cancel="positionShow = false"/>
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.unitList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>

      <van-popup v-model="houseShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.houseList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
      </van-popup>
      <van-cell-group>
        <van-cell   >
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">报事信息</span>
          </template>
        </van-cell>
        <van-cell title="事件类别" :value="selectMsg.category"  @click="categoryshow = true"/>
        <van-cell title="具体地点">
          <van-field
              v-model="selectMsg.address"
              rows="1"
              autosize
              placeholder="请输入"
              style="text-align: right;"
          />
        </van-cell>
        <van-cell title="所在小区" :value="selectMsg.Community" @click="CommunityShow = true"/>
        <van-cell title="所在位置" :value="selectMsg.position"  @click="positionShow = true"/>
        <van-cell title="所在楼幢" :value="selectMsg.Building"  @click="BuildingShow = true" v-if="selectMsg.position == '室内楼幢'"/>
        <van-cell title="所在单元" :value="selectMsg.unit" @click="unitShow = true" v-if="selectMsg.position == '室内楼幢' && selectData.unitList.length >= 1"/>
        <van-cell title="所在房屋" :value="selectMsg.house" @click="houseShow = true" v-if="selectMsg.position == '室内楼幢' && selectData.houseList.length >= 1"/>
        <van-cell title="事件描述" >
          <van-field
            v-model="selectMsg.description"
            rows="4"
            autosize
            type="textarea"
            placeholder="这是一条事件描述"
            style="text-align: right;"
          />
        </van-cell>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader v-model="fileList"  @delete="uploaddel" :after-read="uploadRead"/>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="24" style="padding-top: 80px">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getbelongSubArea, getDictTree} from '@/utils/common'

// import { mapState } from 'vuex'
export default {
  data () {
    return {
      file:{},
      detailData: {},
      categoryshow: false,
      CommunityShow: false,
      positionShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      selectMsg: {
        category: '请选择',
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        description: '',
        address: ''
      },
      submitData: {
        building:'',
        content:'',
        fileMessages:[],
        newFileMessages:[],
        deleteFiles: [],
        house:'',
        location:'',
        orgId:0,
        subarea:'',
        type:'',
        unit:'',
        userId:'',
      },
      selectData: {
        categoryList: [],
        CommunityList: [],
        positionList: [{ label: "公共区域", value: "1" }, { label: "室内楼幢", value: "2" }, { label: "外部区域", value: "100" }],//, { label: "高层建筑", value: "2" }
        BuildingList: [],
        unitList: [],
        houseList: []
      },
      fileList: [],
    }
  },
  // computed:{...mapState(['ProblemId'])},
  components :{
    topBar
  },
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.submitData.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/problem/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.fileMessages.push(data.fileMessage)
            this.submitData.newFileMessages.push(data.fileMessage)
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    init () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.CommunityList = data.subAreaList
          } else {
          this.$toast.fail(data.msg);
          }
        })

      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'problemType'
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.categoryList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    submit () {
      if (this.selectMsg.category === '请选择') {return this.$toast.fail('失败:请先选择事件类型');}
      if (this.selectMsg.position === '请选择') {return this.$toast.fail('失败:请先选择位置');}
      if (this.submitData.location !== '100') {
        if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      }
      if (this.selectMsg.Building === '请选择' && this.submitData.location == 2) {return this.$toast.fail('失败:请先选择楼幢');}
      if (this.selectMsg.description === '') {return this.$toast.fail('失败:请先输入问题描述');}
      this.submitData.content = this.selectMsg.description
      this.submitData.address = this.selectMsg.address
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/save'),
        method: 'post',
        data : this.submitData
      }).then(({data})=> {
          if (data.code == 0) {
          this.$toast.success ('新增成功');
          this.$router.go(-1)
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    // 问题类型选择
    categoryConfirm (value) {
      if (value){
      this.selectMsg.category = value.label
      this.submitData.type = value.value
      }
      this.categoryshow = false
    },
    // 小区选择
    CommunityConfirm (value) {
      console.log(value);
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.position = '请选择'
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.unitList = []
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
        this.selectData.BuildingList = []
        this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: value.id
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 当前位置选择
    positionConfirm (value) {
      if (value) {
        this.selectMsg.position = value.label
        this.submitData.location = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
        this.positionShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value) {
      this.selectMsg.Building = value.label
      this.submitData.building = value.value
      this.selectMsg.unit = '请选择'
      this.submitData.unit = ''
      this.selectData.houseList = []
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value.value
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.unitList = data.units
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.BuildingShow = false
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
      this.selectMsg.unit = value.label
      this.submitData.unit = value.value
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value.value,
          bOru: 2
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            console.log(data);
            this.selectData.houseList = data.houseOptions
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.unitShow = false
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
      this.selectMsg.house = value.label
      this.submitData.house = value.value
      }
      this.houseShow = false
    },
    onCancel() {
    },
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.van-field__control {
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
  .info {
    padding-bottom: 200px;
  }
  .van-divider{
    border-color: #000;
  }
  .van-field {
    padding: 0;
  }
</style>
